import { LeaderboardType, LeaderboardUser } from 'types/leaderboard.types';

import RestApiService from './';

export default {
  getLeaderboard(this: RestApiService, type: LeaderboardType, reveal?: string) {
    return this.api.get<Array<LeaderboardUser>>('/leaderboard', {
      params: { leaderboardType: type, reveal },
    });
  },
  getUserLeaderboard(
    this: RestApiService,
    userId: string,
    type: LeaderboardType,
    reveal?: string,
  ) {
    return this.api.get<Array<LeaderboardUser>>(
      `/users/${encodeURI(userId)}/leaderboard`,
      {
        params: { leaderboardType: type, reveal },
        authEnabled: true,
      },
    );
  },
};
