import { CssBaseline, ThemeProvider } from '@mui/material';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { PostHogProvider } from 'posthog-js/react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import config from 'config/env';

import { isDev } from 'utils/env.utils';

import { QueryKeys } from 'queries';
import { AnalyticsProvider } from 'services/analytics';
import { FeatureFlagsProvider } from 'services/feature-flags';
import history from 'services/history';
import IntlSelectorProvider from 'services/i18n';
import { persistOptions, queryClient } from 'services/react-query';
import storeConfig from 'store';
import theme from 'theme';

import Pages from 'pages/Pages';

import { RootBoundary, RouteBoundary } from 'components/@boundaries';
import { Poller } from 'components/@common';
import { MapProvider } from 'components/@map';
import { ModalWrapper } from 'components/@modal';
import LoadingState from 'components/@states/LoadingState';

const App = () => {
  const handleQueryRestore = async () => {
    if (config.GAME_ACTIVE) {
      await queryClient.invalidateQueries({
        queryKey: QueryKeys.user.me(),
        exact: true,
      });
    }
  };

  return (
    <RootBoundary>
      <PostHogProvider
        apiKey={config.POSTHOG.key}
        options={{
          api_host: config.POSTHOG.host,
          autocapture: false,
          capture_pageview: false,
          capture_pageleave: false,
        }}
      >
        <PersistQueryClientProvider
          client={queryClient}
          persistOptions={persistOptions}
          onSuccess={handleQueryRestore}
        >
          <FeatureFlagsProvider>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <ReduxProvider store={storeConfig.store}>
                <AnalyticsProvider>
                  <PersistGate
                    loading={<LoadingState />}
                    persistor={storeConfig.persistor}
                    onBeforeLift={storeConfig.initApp}
                  >
                    <IntlSelectorProvider>
                      <HelmetProvider>
                        <RouteBoundary>
                          {/* @ts-expect-error history mismatch */}
                          <HistoryRouter history={history}>
                            <MapProvider>
                              <ModalWrapper>
                                {config.GAME_ACTIVE && <Poller />}
                                <Pages />
                              </ModalWrapper>
                            </MapProvider>
                          </HistoryRouter>
                        </RouteBoundary>
                      </HelmetProvider>
                    </IntlSelectorProvider>
                  </PersistGate>
                </AnalyticsProvider>
              </ReduxProvider>
            </ThemeProvider>
            {isDev() && (
              <ReactQueryDevtools
                initialIsOpen={false}
                buttonPosition="bottom-right"
              />
            )}
          </FeatureFlagsProvider>
        </PersistQueryClientProvider>
      </PostHogProvider>
    </RootBoundary>
  );
};

export default App;
