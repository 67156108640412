import { ScoreBreakDown } from 'types/breakdown.types';
import { PinPlacement, User } from 'types/user.types';

import RestApiService from './';

export default {
  getUser(this: RestApiService) {
    return this.api.get<User>('/users/me', { authEnabled: true });
  },
  getScoreBreakdown(this: RestApiService, userId: string, reveal?: string) {
    return this.api.get<ScoreBreakDown>(
      `/users/${encodeURI(userId)}/score-breakdown`,
      {
        authEnabled: true,
        params: { reveal },
      },
    );
  },
  getUserPin(this: RestApiService, userId: string, episodeId: string) {
    return this.api.get<PinPlacement>(
      `/users/${encodeURI(userId)}/episodes/${episodeId}/pin`,
      { authEnabled: true },
    );
  },
  postUserPin(
    this: RestApiService,
    userId: string,
    episodeId: string,
    position: { latitude: number; longitude: number },
  ) {
    return this.api.post<PinPlacement>(
      `/users/${encodeURI(userId)}/episodes/${episodeId}/pin`,
      position,
      { authEnabled: true },
    );
  },
};
