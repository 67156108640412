import config from 'config';

import { isTokenValid } from 'utils/token.utils';
import { inIframe } from 'utils/window.utils';

import { startAppListening } from 'services/redux/listenerMiddleware';
import store from 'store';

import { getAccessToken } from './auth.selectors';
import { authActions } from './auth.slice';

export default function startAuthListeners() {
  startAppListening({
    predicate: (action) => action.type === authActions.LOGIN_START.type,
    effect: (_, listenerApi) => {
      if (config.AUTH_TYPE === 'cookie') {
        const loginUrl = `${config.AUTH_API_BASE}?callback_url=${
          inIframe() ? config.IFRAME_CLIENT_URL : config.CLIENT_URL
        }`;

        listenerApi.cancelActiveListeners();
        if (window.top && inIframe()) {
          window.top.location.href = loginUrl;
        } else {
          window.location.href = loginUrl;
        }
      } else if (config.AUTH_TYPE === 'token') {
        const state = listenerApi.getState();
        const token = getAccessToken(state);

        // if token is stored and expired (not valid), do silent login
        const promptParam = token && !isTokenValid(token) ? '&prompt=none' : '';

        listenerApi.cancelActiveListeners();
        if (window.top && inIframe()) {
          window.top.location.href = `${config.AUTH_API_BASE}/authorize?client_id=${config.AUTH_CLIENT_ID}&response_type=code&redirect_uri=${config.IFRAME_CLIENT_URL}&scope=openid email profile${promptParam}`;
        } else {
          window.location.href = `${config.AUTH_API_BASE}/authorize?client_id=${config.AUTH_CLIENT_ID}&response_type=code&redirect_uri=${config.CLIENT_URL}&scope=openid email profile${promptParam}`;
        }
      }
    },
  });
  startAppListening({
    predicate: (action) => action.type === authActions.LOGOUT.type,
    effect: async () => {
      if (config.AUTH_TYPE === 'token') {
        const logoutUrl = `${config.AUTH_API_BASE}/session/end?client_id=${
          config.AUTH_CLIENT_ID
        }&post_logout_redirect_uri=${
          config.AUTH_LOGOUT_REDIRECT_URI || config.CLIENT_URL
        }`;

        try {
          // Force flush or else the token will still be in the store
          await store.persistor.flush();
        } catch {
          // If failed we wait 1 second and hopefully it was saved
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }

        if (window.top && inIframe()) {
          window.top.location.href = logoutUrl;
        } else {
          window.location.href = logoutUrl;
        }
      }
    },
  });
}
