import config from 'config/env';

import {
  ApiEpisode,
  Participant,
  PartnerParams,
  SelectPartnerParams,
} from 'types/episode.types';
import { Coordinate } from 'types/game.types';

import RestApiService from './';

export default {
  getEpisodes(this: RestApiService) {
    return this.api.get<Array<ApiEpisode>>(`/episodes`);
  },
  getPartner(this: RestApiService, { userId, episodeId }: PartnerParams) {
    return this.api.get<Participant>(
      `/users/${encodeURI(userId)}/episodes/${episodeId}/partner`,
      { authEnabled: true },
    );
  },
  selectPartner(
    this: RestApiService,
    { userId, episodeId, participant }: SelectPartnerParams,
  ) {
    return this.api.post<Participant>(
      `/users/${encodeURI(userId)}/episodes/${episodeId}/partner`,
      {
        participantId: participant.id,
      },
      { authEnabled: true },
    );
  },
  getHeatmapEpisodes(this: RestApiService, production = true) {
    if (production) {
      return this.api.get<Array<ApiEpisode>>(
        `${config.PRODUCTION_API_BASE}/episodes`,
      );
    }
    return this.api.get<Array<ApiEpisode>>('/episodes');
  },
  getHeatmap(
    this: RestApiService,
    episodeId: string,
    gold = false,
    production = true,
  ) {
    if (gold) {
      if (production) {
        return this.api.get<Array<Coordinate>>(
          `${config.PRODUCTION_API_BASE}/episodes/${episodeId}/golden-x/heatmap`,
        );
      } else {
        return this.api.get<Array<Coordinate>>(
          `/episodes/${episodeId}/golden-x/heatmap`,
        );
      }
    } else {
      if (production) {
        return this.api.get<Array<Coordinate>>(
          `${config.PRODUCTION_API_BASE}/episodes/${episodeId}/heatmap`,
        );
      } else {
        return this.api.get<Array<Coordinate>>(
          `/episodes/${episodeId}/heatmap`,
        );
      }
    }
  },
};
