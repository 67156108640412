import { QueryFilters, useQueryClient } from '@tanstack/react-query';
import _isEqual from 'lodash/isEqual';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { Keys, QueryKeys } from 'queries/QueryKeys';
import { authActions } from 'store/auth/auth.slice';

export const useLogout = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const cleanup = useCallback(async () => {
    const filters: QueryFilters = {
      queryKey: [Keys.User],
      predicate: (query) => {
        // We only clear the cache if the queryKey is not the current user
        if (_isEqual(query.queryKey, QueryKeys.user.me())) {
          return false;
        }
        return true;
      },
    };
    await queryClient.cancelQueries(filters);
    queryClient.removeQueries(filters);

    // Wait for the cleanup to finish before logging out
    // Flushing the persistor would be better but currently didn't find the best way to do this
    await new Promise((resolve) => setTimeout(resolve, 1000));
  }, [queryClient]);

  const logout = useCallback(async () => {
    await cleanup();

    dispatch(authActions.LOGOUT());
  }, [cleanup, dispatch]);

  return {
    logout,
  };
};
