import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getTime, parseISO } from 'date-fns';
import _maxBy from 'lodash/maxBy';
import _minBy from 'lodash/minBy';
import { useMemo } from 'react';

import { CacheTime, StaleTime } from 'queries/constants';
import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import { useFeatureFlags } from 'services/feature-flags';
import { DateString } from 'types/data.types';
import { Episode, PastEpisode } from 'types/episode.types';
import { Feature } from 'types/features.types';
import { FinalGoldenX } from 'types/golden-x.types';

const getEpisodes = async (goldenX = false, goldenXEndDate?: DateString) => {
  const { data } = await apiService.getEpisodes();

  if (!goldenX) {
    return data as Array<Episode>;
  }

  // We set the golden x dates to the episodes, is easier for later use
  const finalData = data.map((episode, index) => {
    // Last episode (array is sorted from latest to oldest)
    if (index === 0 || episode.isLastEpisode) {
      return {
        ...episode,
        goldenXStartDate: episode.startDate,
        goldenXEndDate: goldenXEndDate,
      };
    }
    const nextEpisode = data[index - 1];

    return {
      ...episode,
      goldenXStartDate:
        episode.number === 1 ? episode.votesLockedDate : episode.startDate,
      goldenXEndDate: nextEpisode.startDate,
    };
  });

  return finalData as Array<Episode>;
};

export const useEpisodes = () => {
  const queryClient = useQueryClient();

  const { hasFeature } = useFeatureFlags();
  const { data, isLoading } = useQuery({
    queryKey: QueryKeys.episode.all(),
    queryFn: async () => {
      const goldenX = queryClient.getQueryData<FinalGoldenX>(
        QueryKeys.goldenX.final(),
      );

      const episodes = await getEpisodes(
        hasFeature(Feature.GoldenX),
        goldenX?.votesLockedDate,
      );

      return episodes;
    },
    staleTime: StaleTime.TEN_SECONDS,
    gcTime: CacheTime.FOREVER,
    meta: { persist: true },
  });

  const nextEpisode = useMemo(() => {
    const liveEpisode = data?.find((e) => e.isLive && !e.isRevealed);

    if (liveEpisode) {
      return liveEpisode;
    }

    // If the episode isn't live we check for the last episode by start
    const nextEpisodes = data?.filter((e) => !e.isRevealed);

    const nextEpisode = _minBy(nextEpisodes, (e) => {
      return getTime(parseISO(e.startDate));
    });

    return nextEpisode;
  }, [data]);

  const currentEpisode = useMemo(() => {
    const liveEpisodes = data?.filter((e) => e.isLive);

    // We take the last live episode. Just in case they forgot to disable the previous episode
    const liveEpisode = _maxBy(liveEpisodes, (e) => {
      return getTime(parseISO(e.startDate));
    });

    if (!liveEpisode) {
      return nextEpisode;
    }

    return liveEpisode;
  }, [data, nextEpisode]);

  const pastEpisodes = useMemo(() => {
    return data?.filter((e) => e.isRevealed && !!e.reveal) as
      | Array<PastEpisode>
      | undefined;
  }, [data]);

  return {
    episodes: data,
    nextEpisode,
    currentEpisode,
    pastEpisodes,
    isLoading,
  };
};
